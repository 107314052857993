@use 'sass:map';

@import 'src/variables';

@import '~@edgebox/react-components/src/common.scss';
@import '~@edgebox/react-components/src/boostrap-overrides.scss';
@import '~@edgebox/react-components/src/styles.scss';

@import 'common/styles.scss';

@import '@xyflow/react/dist/style.css';

.flex-basis-100 {
  flex-basis: 100%;
}
.min-width-0 {
  min-width: 0;
}
.min-height-0 {
  min-height: 0;
}

.bg-lighter {
  background: rgba(0, 0, 0, 0.025);
}
.bg-soft-highlight {
  background: radial-gradient(circle at 50% -50%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(
      circle at 50% 10%,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 15%,
      rgba(0, 0, 0, 0.01) 50%,
      rgba(0, 0, 0, 0.05) 150%
    );
}

.layout-v2 {
  .nav.nav-tabs {
    padding: 0.5em 0;
    border-bottom: 1px solid theme-color('light');
    .nav-item {
      margin-right: 0.5em;
      .nav-link {
        border-radius: 3px;
        &.active {
          background: theme-color('primary');
          color: #{map.get($theme-complementary-colors, 'primary')};
        }
      }
    }
  }
}

.screenshot {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.accordion-button {
  box-shadow: none !important;
  background: transparent !important;
}
.accordion-button:not(.collapsed) {
  color: black;
  background: white;
}

.btn {
  &.btn-primary {
    --bs-btn-color: #{map.get($theme-complementary-colors, 'primary')};
    --bs-btn-hover-color: #{map.get($theme-complementary-colors, 'primary')};
    --bs-btn-active-color: #{map.get($theme-complementary-colors, 'primary')};
    --bs-btn-disabled-color: #{map.get($theme-complementary-colors, 'primary')};
  }
  &.btn-primary-action {
    background-image: linear-gradient(135deg, #009ee3 0%, #7491ed 34%, #d96ecc 68%, #ff5479 100%);
    &:active,
    &:hover {
      background-image: linear-gradient(
        135deg,
        #{shift-color(#009ee3, -50 * $theme-color-interval)} 0%,
        #{shift-color(#7491ed, -50 * $theme-color-interval)} 34%,
        #{shift-color(#d96ecc, -50 * $theme-color-interval)} 68%,
        #{shift-color(#ff5479, -50 * $theme-color-interval)} 100%
      );
    }
    &:not(:disabled):not(.disabled):not(.button-checkbox):not(.shadow):not(.shadow-sm) {
      &:active,
      &:hover {
        box-shadow: 0 0 10px 1px #ff6489bb !important;
      }
    }
    &.disabled,
    &:disabled {
      background-image: none;
    }
  }
}

body .btn:not(:disabled):not(.disabled) {
  border-radius: 0;
  box-shadow: none !important;
  border: none;

  &.btn-primary {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: #{shift-color($primary, -3 * $theme-color-interval)};
    }
  }
}

body a {
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: #{shift-color($primary, -3 * $theme-color-interval)};
  }
}

.form-control,
.form-select,
.form-check-input {
  box-shadow: none !important;
}

.nav-link {
  border: none !important;
}

.tab-pane {
  > .content-box {
    border-top-left-radius: 0 !important;
  }
}

.badge {
  &.bg-light {
    --bs-badge-color: #{map.get($theme-complementary-colors, 'light')};
  }
}

.border-1 {
  border-width: 1px !important;
}
.border-2 {
  border-width: 3px !important;
}
.border-3 {
  border-width: 6px !important;
}
.border-4 {
  border-width: 10px !important;
  &.rounded-top {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
  &.rounded-bottom {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
}
.border-5 {
  border-width: 15px !important;
}

form .row {
  margin-bottom: 1rem;
  .form-label {
    margin: 0;
  }
}

body .btn.btn-primary:not(.disabled),
body .btn.btn-primary.disabled {
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
}

.react-date-picker__wrapper {
  padding: 4px 8px;
  border-radius: 4px;
}

.flex-1-1 {
  flex: 1 1;
}

.tooltip {
  &.tooltip-wide {
    .tooltip-inner {
      max-width: 400px;
    }
  }
  .tooltip-inner {
    ul {
      margin: 0;
      padding-left: 15px;
      li {
        margin: 0;
        text-align: left;
      }
    }
  }
}

body,
html body {
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  margin: 0;
  padding: 0;

  font-family: 'Open Sans', PTSansRegular, Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
}

#root {
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  background: #f6f6f6;
}

#page-content {
  height: 100%;
  background: #f4f4f4;
  padding: 1em 0 1em 1em;
}

.row {
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

h1,
.h1 {
  font-weight: bold;
}
.content-box {
  box-shadow: 0 0 15px rgb(28 31 28 / 5%);
}
.alert {
  box-shadow: 0 0 15px rgb(28 31 28 / 5%);
}
.card {
  box-shadow: 0 0 15px rgb(28 31 28 / 5%);
}

.content-box .card {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

#terms-page {
  background-color: white;
  padding: 40px;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  border: 0 solid #c2c8d0;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
}

#terms-page header {
  padding-bottom: 20px;
}

#terms-page header img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alert {
  border-width: 0 0 0 5px;
  background: white;
  padding: 0.5rem 0.75rem;

  &.alert-danger {
    border-color: theme-color('danger');
    color: theme-color('danger');
  }

  &.alert-warning {
    border-color: theme-color('warning');
    color: theme-color('warning');
  }

  &.alert-success {
    border-color: theme-color('success');
    color: theme-color('success');
  }

  &.alert-primary {
    border-color: theme-color('primary');
    color: theme-color('primary');
  }

  &.alert-light {
    border-color: theme-color('dark');
    color: theme-color('dark');
  }

  &.hero {
    border-width: 0;

    color: white;

    a,
    .btn-link,
    .btn-light,
    .btn:not(:disabled):not(.disabled):not(.button-checkbox).btn-light {
      color: white;
      > svg {
        color: white !important;
      }
    }

    &.alert-danger {
      background-color: theme-color('danger');
    }

    &.alert-warning {
      background-color: theme-color('warning');
    }

    &.alert-success {
      background-color: theme-color('success');
    }

    &.alert-primary {
      background-color: theme-color('primary');
      .btn {
        &.btn-primary {
          background-color: white;
          color: theme-color('primary');
          &:hover {
            background-color: #eee;
            color: theme-color('primary');
          }
        }
      }
      h3 {
        color: #eee;
      }
    }

    &.alert-light {
      background-color: theme-color('dark');
    }
  }
}

.btn {
  &.btn-danger {
    color: map.get($theme-complementary-colors, 'danger');
    &:disabled,
    &.disabled {
      color: theme-color('danger');
    }
  }
  &.btn-primary {
    color: map.get($theme-complementary-colors, 'primary');
  }
}

.btn:not(:disabled):not(:disabled):not(.button-checkbox) {
  &.btn-danger,
  &.btn-danger:hover,
  &.btn-danger:active,
  &.btn-danger:focus,
  &.btn-danger:active:focus {
    border: 3px solid theme-color('danger');
    border-radius: 9px;
  }

  &.btn-warning,
  &.btn-warning:hover,
  &.btn-warning:active,
  &.btn-warning:focus,
  &.btn-warning:active:focus {
    border: 3px solid theme-color('warning');
    border-radius: 9px;
  }
}

.flex-even {
  flex: 1;
  flex-basis: 100%;
}

.hover-primary-border {
  border: 2px solid transparent;
  &:hover {
    border: 2px solid $primary;

    .form-check-input {
      border-color: $primary;
    }
  }
}

.hover-shadow {
  box-shadow: none;
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
}

.select-exclusive-children {
  &:hover {
    > * {
      opacity: 50%;
      background: $light;
      &:hover {
        background: transparent;
        opacity: 100%;
      }
    }
  }
}

.pricing-container {
  .form-check-input {
    border-width: 2px;
  }
  .pricing-row {
    > * {
      border: 3px solid transparent;
      border-top-width: 0px;
      border-bottom-width: 0px;
    }
    &:first-child {
      > * {
        border-top-width: 3px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
    &:last-child {
      > * {
        border-bottom-width: 3px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  &.hover-0 {
    .pricing-row {
      > :nth-child(1) {
        border-color: $primary;
        .form-check-input {
          border-color: $primary;
        }
      }
    }
  }
  &.hover-1 {
    .pricing-row {
      > :nth-child(3) {
        border-color: $primary;
        .form-check-input {
          border-color: $primary;
        }
      }
    }
  }
  &.hover-2 {
    .pricing-row {
      > :nth-child(5) {
        border-color: $primary;
        .form-check-input {
          border-color: $primary;
        }
      }
    }
  }
}

/* Temporarily overwrite iframe-resize behavior. */
.iframe-modal {
  z-index: 1000000000;
  position: fixed;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  height: 100vh !important;
  width: 100vh !important;
}
