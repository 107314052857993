@use 'sass:map';

$primary: #009ee3;
$secondary: #999999;
$success: #00e370;
$info: #009ee3;
$warning: #e38600;
$danger: #e30000;
$light: #d4d4d4;
$lighter: #f8f8f8;
$dark: #616161;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);

$theme-complementary-colors: (
  'primary': #ffffff,
  'secondary': #000000,
  'success': #000000,
  'info': #000000,
  'warning': #000000,
  'danger': #ffffff,
  'light': #444444,
  'dark': #ffffff,
);

// Abstract
$component-active-bg: map.get($theme-colors, 'primary') !default;
$component-active-color: map.get($theme-complementary-colors, 'primary') !default;
$success: map.get($theme-colors, 'success') !default;
$danger: map.get($theme-colors, 'danger') !default;

// Navigation
$link-color: map.get($theme-colors, 'primary') !default;
$nav-pills-link-active-bg: map.get($theme-colors, 'primary') !default;

// Forms
$input-focus-border-color: map.get($theme-colors, 'primary') !default;
//$form-check-input-bg: map.get($theme-colors, "primary") !default;

$theme-color-interval: 0.1 !default;

@import '~bootstrap/scss/bootstrap';
@import '~@edgebox/react-components/src/simplistic.scss';

$card-columns-margin: 0.25em !default;
$card-columns-count: 3 !default;
$card-columns-gap: 0.25em !default;

// Used for react-select components (e.g. entity selectors)
:root {
  --primary: #{$primary};
  --danger: #{$danger};

  --primary-darker: #{darken($primary, 10%)};
  --primary-dark: #{darken($primary, 15%)};
  --primary-lighter: #{lighten($primary, 10%)};
  --primary-lighter-50: #{lighten($primary, 10%)};

  --highlight: #ff5479;
  --highlight-darker: #f84469;
  --highlight-lighter: #ff6489;
  --highlight-lighter-50: #ff648988;

  --dark: #{darken($dark, 20%)};
  --dark-darker: #{darken($dark, 10%)};
  --dark-lighter: #{lighten($dark, 5%)};
  --dark-lighter-50: #{lighten($dark, 0%)};
}
