.login {
  .logo {
    text-align: center;
    img {
      text-align: center;
      margin: 5rem 0 0 0;
    }
  }

  h1 {
    display: none;
    text-align: center;
  }

  h2 {
    margin: 3rem 0 5rem 0;
    font-weight: bold;
    text-align: center;
  }

  .card-text {
    min-height: 80px;
  }

  .card-footer {
    text-align: center;
  }
}
