.entity-selector {
  .item {
    &:not(.disabled) {
      cursor: pointer;
    }

    transition: background-color 200ms;
    border-radius: 5px;

    &.selected {
      background-color: map-get($theme-colors, 'primary');
      color: #fff;
    }

    &:not(.disabled):hover {
      background-color: map-get($theme-colors, 'light');
      color: #000;
    }
  }
}
